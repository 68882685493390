import React, {useState, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import {FORM_ERROR} from 'final-form';
import {MenuItem, Paper} from '@material-ui/core';

import {
  FieldsContainer,
  FormTitle,
  FormSectionTitle,
  SubmitButton,
} from '../../utils/commonStyledComponents';
import StatusAlert from '../StatusAlert';
import ContactInfo from './ContactInfo';
import {FormError, TextField} from './';
import {mutators, participantInitialValues} from './utils';
import {validateUpdateParticipant} from '../Form/validation';
import {capitalize} from '../../utils/formatString';
import {updateMemberRelationships} from '../../utils/api';

import {
  RELATION_GENITORE,
  RELATION_FIGLIO,
  RELATION_COMPAGNO,
  RELATION_CONIUGE,
  RELATION_ALTRO,
} from '../../utils/constants';

const RELATIONSHIP_TYPES = [
  RELATION_GENITORE,
  RELATION_FIGLIO,
  RELATION_COMPAGNO,
  RELATION_CONIUGE,
  RELATION_ALTRO,
];

const UpdateParticipant = ({participant, type, id, cities}) => {
  const [showAlert, setShowAlert] = useState(false);

  const onSubmit = useCallback(
    async data => {
      try {
        await updateMemberRelationships(id, data);

        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 4000);
      } catch (e) {
        const {data} = e.response || {};

        if (data.errors) {
          const {type, member} = data.errors;
          return {...member, type};
        }

        return {
          [FORM_ERROR]:
            data.message ||
            'Si è verificato un errore nella comunicazione con il server',
        };
      }
    },
    [id, setShowAlert]
  );

  const initialValues = useMemo(
    () => participantInitialValues(participant, type),
    [participant, type]
  );

  return (
    <Form
      onSubmit={onSubmit}
      mutators={mutators}
      initialValues={initialValues}
      validate={validateUpdateParticipant}
      render={({
        values,
        form,
        handleSubmit,
        submitting,
        submitError,
        hasValidationErrors,
      }) => (
        <form onSubmit={handleSubmit} noValidate={true}>
          <FormContainer>
            <FieldsContainer>
              {!submitting && submitError && (
                <FormError>{submitError}</FormError>
              )}

              <FormTitle>Participante</FormTitle>

              <Select name="type" label="Relazione" required>
                {RELATIONSHIP_TYPES.map((relation, index) => (
                  <MenuItem value={relation} key={index}>
                    {capitalize(relation)}
                  </MenuItem>
                ))}
              </Select>

              <FormSectionTitle>Informazioni Partecipante</FormSectionTitle>

              <ContactInfo values={values} cities={cities} form={form} />
            </FieldsContainer>
          </FormContainer>

          <SubmitButton
            text="Aggiorna"
            loading={submitting}
            disabled={submitting || hasValidationErrors}
          />

          <StatusAlert visible={showAlert}>
            Aggiornamento avvenuto con successo
          </StatusAlert>
        </form>
      )}
    />
  );
};

UpdateParticipant.propTypes = {
  cities: PropTypes.array,
  participant: PropTypes.object,
  type: PropTypes.string,
  id: PropTypes.number,
};

export default UpdateParticipant;

const FormContainer = styled(Paper)`
  background-color: ${props => props.theme.palette.grey[100]};
`;

const Select = styled(TextField).attrs({select: true, disabled: true})`
  width: 200px;
  margin: 8px;
`;
