import React from 'react';
import ImageEuro from '@material-ui/icons/EuroSymbol'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import PropTypes from 'prop-types';
import {formatPrice} from "../utils/commonFunctions";

const EditPracticeSidebar = ({preview}) => {

    const renderPrice = (label, value) => {
        return <React.Fragment>
            <dt>{label}:</dt>
            {' '}
            <dd>
                {`${value ? formatPrice(value) : '-,--'}`}
            </dd>
        </React.Fragment>;
    };

    return (
        <Grid className="form-sidebar fixed aside-booking">
            <List className="aside-list">
                <div className="aside-panel">
                    <ListItem className="aside-title">
                        <ListItemAvatar>
                            <Avatar className="highlight-color">
                                <ImageEuro/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Riepilogo"/>
                    </ListItem>

                    {
                        preview && <ListItem className="aside-content">
                            <dl className="price-si">
                                <div className="price-specs">
                                    {renderPrice("Prezzo base", preview.basePrice)}
                                    {preview.rows?.map((row) => renderPrice(row.description, row.price))}
                                    {renderPrice("Prezzo totale", preview.totalPrice)}
                                </div>
                            </dl>
                        </ListItem>
                    }
                </div>

            </List>
        </Grid>
    );
};

EditPracticeSidebar.propTypes = {
    preview: PropTypes.object.isRequired,
};

export default EditPracticeSidebar;
