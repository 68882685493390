import React, {useState, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {FORM_ERROR} from 'final-form';
import {Form} from 'react-final-form';
import {Paper} from '@material-ui/core';

import StatusAlert from '../StatusAlert';
import ContactInfo from './ContactInfo';
import TermsAndConditions from './TermsAndConditions';
import {
  FieldsContainer,
  FormTitle,
  SubmitButton,
} from '../../utils/commonStyledComponents';
import {FormError} from './';
import {mutators, memberInitialValues} from './utils';
import {validateUpdateMember} from '../Form/validation';
import {useUser} from '../../utils/UserSession';
import {updateMemberInfo} from '../../utils/api';

const UpdateMember = ({cities}) => {
  const {user, setUser} = useUser();
  const [showAlert, setShowAlert] = useState(false);

  const onSubmit = useCallback(
    async data => {
      try {
        const confirmedData = await updateMemberInfo(data);

        const updatedUser = {...user, ...confirmedData};

        setUser(updatedUser);

        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 4000);
      } catch (e) {
        const {data} = e.response || {};

        if (data.errors) {
          return data.errors;
        }

        return {
          [FORM_ERROR]:
            data.message ||
            'Si è verificato un errore nella comunicazione con il server',
        };
      }
    },
    [setUser, user]
  );

  const initialValues = useMemo(() => memberInitialValues(user), [user]);

  return (
    <Form
      onSubmit={onSubmit}
      mutators={mutators}
      initialValues={initialValues}
      validate={validateUpdateMember}
      render={({
        values,
        form,
        handleSubmit,
        submitting,
        submitError,
        hasValidationErrors,
      }) => (
        <form onSubmit={handleSubmit} noValidate={true}>
          <FormContainer>
            <FieldsContainer>
              {!submitting && submitError && (
                <FormError>{submitError}</FormError>
              )}

              <FormTitle>Informazioni Personali</FormTitle>

              <ContactInfo values={values} cities={cities} form={form} />

              <TermsAndConditions values={values} />
            </FieldsContainer>
          </FormContainer>

          <SubmitButton
            text="Aggiorna"
            loading={submitting}
            disabled={submitting || hasValidationErrors}
          />

          <StatusAlert visible={showAlert}>
            Aggiornamento avvenuto con successo
          </StatusAlert>
        </form>
      )}
    />
  );
};

UpdateMember.propTypes = {
  cities: PropTypes.array,
};

export default UpdateMember;

const FormContainer = styled(Paper)`
  background-color: ${props => props.theme.palette.grey[200]};
`;
