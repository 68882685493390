import StatusAlert from "../components/StatusAlert";
import React from 'react';

const AlertContext = React.createContext({});

export function AlertProvider({children}) {
    const [alert, setAlert] = React.useState(null);

    const showAlert = ({message, type = 'success', duration = 4000, centered = false}) => {
        setAlert({ message, type, centered });
        // Se la durata è -1, l'alert non viene mai chiuso
        if(duration > -1) {
            setTimeout(() => {
                setAlert(null);
            }, duration);
        }
    };

    return (
        <AlertContext.Provider value={showAlert}>
            {children}
            {alert && <StatusAlert visible={showAlert} severity={alert.type} centered={alert.centered}>
                {alert.message}
            </StatusAlert>}
        </AlertContext.Provider>
    );
}

export const useAlert = () => {
    const context = React.useContext(AlertContext);
    if (!context) {
        throw new Error("useAlert must be used within an AlertProvider");
    }
    return context;
};
