import React from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid,} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const YearItem = ({year, children}) => (
    <Container>
        <Panel defaultExpanded={year === (new Date()).getFullYear()}>
            <Summary>
                <Grid container>
                    <GridItem item xs={12}>
                        <b>Anno {year}</b>
                    </GridItem>
                </Grid>
            </Summary>

            <Details>{children}</Details>
        </Panel>
    </Container>
);

export default YearItem;

YearItem.propTypes = {
    children: PropTypes.node,
    year: PropTypes.number,
};

const Container = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

const Panel = styled(ExpansionPanel).attrs({elevation: 0})`
  width: 100%;
`;

const expansionStyle = css`
  border-radius: 4px;
  border: 1px solid lightgray;
  //min-width: 900px; edit_20231020
`;

const Summary = styled(ExpansionPanelSummary).attrs({
    expandIcon: <ExpandMoreIcon/>,
})`
  ${expansionStyle};
`;

const Details = styled(ExpansionPanelDetails)`
  ${expansionStyle};
  display: block;
  margin-top: 16px;
`;

const GridItem = styled(Grid)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  overflow: hidden;
  padding: 4px 16px;
`;
