import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Typography} from '@material-ui/core';

import {RadioButtons, CheckboxField} from './';

const TermsAndConditions = ({values}) => (
  <Container>
    {/* privacy policy */}
    <div>
      Informativa estesa sulla <PrivacyPolicyLink>privacy</PrivacyPolicyLink>.
      Ai sensi e per gli effetti dell'art.4 DPR 445/2000, in relazione al rilascio di dichiarazioni mendaci, dichiaro che le informazioni rese nel presente form corrispondono a verità e accetto che queste vengano utilizzate unicamente per le finalità per le quali sono state acquisite (GDPR - Regolamento 2016/679). Per quanto riguarda i consensi da lei sottoscritti le comunichiamo che limiteremo le nostre attività sui vostri dati, per le finalità indicate nell’informativa, entro i limiti delle autorizzazioni che ci avete concesso e al fine di verificare i consensi sottoscritti può inviare una mail al seguente indirizzo:{' '}
      <HighlightText>privacy@giocamondo.it</HighlightText>.
    </div>
    <CheckboxField
      name="privacyAccepted"
      label="Dichiaro di aver preso visione della privacy policy"
      value={values.privacyAccepted}
      required
      disabled
    />

    {/* marketing */}
    <div>
      Consenti il trattamento dei Tuoi dati personali per il ricevimento della
      Newsletter aziendale, di comunicazioni relative a nostri servizi,
      prodotti, promozioni per finalità di marketing diretto?{' '}
      <PrivacyPolicyLink>Leggi qui</PrivacyPolicyLink> l'informativa estesa
      sulla privacy per maggiori dettagli.
    </div>
    <RadioButtons
      name="marketingAccepted"
      options={[
        {label: 'Acconsento', value: true},
        {label: 'Non Acconsento', value: false},
      ]}
    />

    {/* profilationAccepted */}
    <div>
      Consenti il trattamento dei dati personali e particolari, come quelli
      previsti dagli articoli 9 e 10 del GDPR “categorie particolari di dati
      personali” (es. stato di salute) Suoi o dei Suoi familiari, anche minori,
      da Lei inseriti ai fini dell’esecuzione delle misure precontrattuali così
      come indicato nell’informativa. Nel momento in cui inserisce dati di
      questa tipologia essi diventano estremamente rilevanti ai fini dello
      svolgimento del servizio da noi fornito.{' '}
      <PrivacyPolicyLink>Leggi qui</PrivacyPolicyLink> l'informativa estesa
      sulla privacy per maggiori dettagli.
    </div>
    <RadioButtons
      name="profilationAccepted"
      options={[
        {label: 'Acconsento', value: true},
        {label: 'Non Acconsento', value: false},
      ]}
    />

    {/* profilation2Accepted */}
    <div>
      Consenti il trattamento dei Tuoi dati personali per permetterci di fornire
      servizi, prodotti e promozioni personalizzati (finalità di profilazione)?{' '}
      <PrivacyPolicyLink>Leggi qui</PrivacyPolicyLink> l'informativa estesa
      sulla privacy per maggiori dettagli.
    </div>
    <RadioButtons
      name="profilation2Accepted"
      options={[
        {label: 'Acconsento', value: true},
        {label: 'Non Acconsento', value: false},
      ]}
    />
  </Container>
);

TermsAndConditions.propTypes = {
  values: PropTypes.object,
};

export default TermsAndConditions;

const Container = styled(Typography).attrs({
  component: 'div',
  variant: 'caption',
})`
  margin: 32px 8px;
`;

const HighlightText = styled(Typography).attrs({
  component: 'span',
  variant: 'caption',
})`
  color: blue;
`;

const PrivacyPolicyLink = styled.a.attrs({
  href: 'https://www.iubenda.com/privacy-policy/883329',
  title: 'Informativa privacy Giocamondo',
  rel: 'noopener noreferrer',
  target: '_blank',
})``;
