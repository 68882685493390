import formatDate from "date-fns/format";

export const getPracticeYears = (practices) => {
    const set = new Set(practices.map(practice => {
        const param = getParam(practice);
        return param ? (new Date(param)).getFullYear() : -1;
    }));
    const arrRes = Array.from(set);
    return arrRes.sort().reverse();
};

export const filterPracticesByYear = (practices, year) => practices.filter(practice => {
    const param = getParam(practice);
    return (new Date(param)).getFullYear() === year;
});

function getParam(practice) {
    let param;
    switch (window.location.pathname) {
        case "/practices":
        case "/upselling":
            param = practice.buyedPackage.periodTo;
            break;
        case "/payments":
            param = practice.buyedPackage.periodTo;
            break;
        case "/documents":
            param = practice.originalPackagePeriodTo;
            break;
    }
    return param;
}

export const formatPrice = (value) => (value / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})

export const customFormatDate = (date) => formatDate(new Date(date), 'dd/MM/yyyy');
