import {createMuiTheme} from '@material-ui/core/styles';

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#f3b117',
    },
    secondary: {
      main: '#ffffff',
    },
    avatar: '#7C5CCC',
    table: {head: '#d3e8eb'},
    text: {
      primary: '#000000',
      secondary: '#808080',
    },

    success: {
      main: '#008000',
      light: '#32CD32',
    },

    info: {
      main: '#0000FF',
    },

    warning: {
      main: '#ff6700',
    },

    error: {
      main: '#ff0000',
    },
  },
  typography: {
    h1: {
      fontWeight: 600,
      fontSize: '32px',
    },
    h2: {
      fontWeight: 600,
      fontSize: '26px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '20px',
    },
    h6: {
      fontWeight: 600,
      fontSize: '14px',
    },
  },
});

export default defaultTheme;
