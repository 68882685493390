import React, {useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {useField} from 'react-final-form';
import MaterialAutocomplete from '@material-ui/lab/Autocomplete';
import {Visibility, VisibilityOff} from '@material-ui/icons';

import {
  FormControlLabel,
  Checkbox,
  Radio,
  InputAdornment,
  Typography,
  TextField as MaterialTextField,
} from '@material-ui/core';

export const TextField = props => {
  const inputProps = useField(props.name, props);

  const fieldProps = {
    ...inputProps.input,
    ...props,
    error: !!(inputProps.meta.error || inputProps.meta.submitError),
    helperText:
      inputProps.meta.error || inputProps.meta.submitError || props.helperText,
  };

  return <MaterialTextField {...fieldProps} />;
};
TextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
};

export const PasswordField = props => {
  const [visiblePassword, setVisiblePassword] = useState(false);

  const toggleVisibility = () => {
    setVisiblePassword(!visiblePassword);
  };

  const InputProps = {
    endAdornment: (
      <Adornment onClick={toggleVisibility}>
        {visiblePassword ? (
          <VisibilityOff fontSize="small" />
        ) : (
          <Visibility fontSize="small" />
        )}
      </Adornment>
    ),
  };

  return (
    <TextField
      {...props}
      InputProps={InputProps}
      type={visiblePassword ? 'text' : 'password'}
    />
  );
};

export const AutocompleteField = ({name, label, ...props}) => {
  const [showOptions, setShowOptions] = useState(false);
  const inputProps = useField(name, props);

  const fieldProps = {
    label,
    error: !!(inputProps.meta.error || inputProps.meta.submitError),
    helperText:
      inputProps.meta.error || inputProps.meta.submitError || props.helperText,
  };

  return (
    <Autocomplete
      {...props}
      onChange={(_, value) => {
        inputProps.input.onChange(value);
        setShowOptions(false);
      }}
      onInputChange={(_, value) => {
        if (value !== inputProps.input.value) {
          setShowOptions(value.length > 2);
        }
      }}
      value={inputProps.input.value}
      onBlur={inputProps.input.onBlur}
      onFocus={inputProps.input.onFocus}
      open={showOptions}
      renderInput={params => <MaterialTextField {...fieldProps} {...params} />}
    />
  );
};
AutocompleteField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  cb: PropTypes.func,
};

export const CheckboxField = props => {
  const inputProps = useField(props.name, {
    type: 'checkbox',
    ...props,
  });

  const fieldProps = {
    ...inputProps.input,
    ...props,
    error: !!(inputProps.meta.error || inputProps.meta.submitError),
    helperText:
      inputProps.meta.error || inputProps.meta.submitError || props.helperText,
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={fieldProps.value}
            value={fieldProps.value}
            onChange={fieldProps.onChange}
            disabled={props.disabled}
          />
        }
        label={fieldProps.label}
      />
      {fieldProps.error && <ErrorMessage>{fieldProps.helperText}</ErrorMessage>}
    </>
  );
};
CheckboxField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
};

export const RadioButtons = props => {
  const inputProps = useField(props.name, {
    ...props,
  });

  const error = !!(inputProps.meta.error || inputProps.meta.submitError);
  const helperText =
    inputProps.meta.error || inputProps.meta.submitError || props.helperText;

  return (
    <>
      {props.options.map((option, index) => (
        <FormControlLabel
          key={index}
          control={
            <Radio
              name={props.name}
              color="primary"
              checked={inputProps.input.value === option.value}
              value={option.value}
              onChange={e =>
                e.target.checked && inputProps.input.onChange(option.value)
              }
            />
          }
          label={option.label}
        />
      ))}

      {error && <ErrorMessage>{helperText}</ErrorMessage>}
    </>
  );
};
RadioButtons.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    })
  ),
};

export const ErrorMessage = styled(Typography).attrs({
  component: 'h4',
  variant: 'caption',
  color: 'error',
})``;

export const FormError = styled(ErrorMessage)`
  margin-left: 8px;
  margin-bottom: 8px;
`;

const Adornment = styled(InputAdornment)`
  cursor: pointer;
`;

const Autocomplete = styled(MaterialAutocomplete)`
  & > div {
    width: 100%;
  }
`;
