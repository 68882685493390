import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {
  PersonOutline as Member,
  CollectionsBookmark as Practices,
  CreditCard as Payments,
  Assignment as Documents,
  LocalActivity as Voucher,
  School as OneToOne,
  Store as Store,
  Help as Undefined, Help,
  Extension as Upselling
} from '@material-ui/icons';

const Icons = {
  Member,
  Practices,
  Payments,
  Documents,
  Voucher,
  OneToOne,
  Store,
  Undefined,
  Upselling,
};

const IconLink = ({icon, text, to, selected}) => {
  const Icon = Icons[icon] ?? Undefined;

  return (
    <Container selected={selected} to={to}>
      <Icon color={selected ? 'primary' : 'inherit'} />
      <Text>{text}</Text>
    </Container>
  );
};

IconLink.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default IconLink;

const Container = styled(Link)`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 10px 16px;
  box-sizing: border-box;
  text-decoration: none;
  color: ${props => props.theme.palette.grey[700]};

  border-top: 0.5px solid
    ${props =>
      props.selected
        ? props.theme.palette.primary.main
        : props.theme.palette.grey[200]};
`;

const Text = styled(Typography).attrs({
  color: 'textSecondary',
})`
  font-size: 12px;
  margin-left: 8px;
`;
