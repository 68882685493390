import React from 'react';
import styled from 'styled-components';
import {Typography} from '@material-ui/core';

const Logo = () => (
  <LogoContainer>
    <LogoImage />
    <LogoDescription>
      Un mondo di privilegi, attenzioni speciali ed offerte personalizzate.
      Giocamondo premia con tantissimi vantaggi chi ha scelto e continuerà a
      scegliere le nostre vacanze!
    </LogoDescription>
  </LogoContainer>
);

export default Logo;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px auto 0;
  max-width: 600px;
`;

const LogoImage = styled.img.attrs({
  alt: 'Giocamondo Logo',
  src: require('../assets/giocamondo-logo.png'),
})`
  width: 400px;
  margin-bottom: 16px;
`;

const LogoDescription = styled(Typography).attrs({
  align: 'center',
  variant: 'h6',
  color: 'textSecondary',
})``;
