import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {Input, Button, CircularProgress} from '@material-ui/core';
import {Edit, Save, Check, Close} from '@material-ui/icons';

//button states
const EDITING = 'editing';
const SUBMITTING = 'submitting';
const SUCCESS = 'success';
const ERROR = 'error';
const DEFAULT = 'default';

class ToggleEditGroup extends Component {
  state = {
    inputState: DEFAULT,
  };

  onUpdate = async () => {
    if (!this.props.shouldUpdate()) {
      this.setState({inputState: DEFAULT});
      this.props.setChildrenEditable(false);
      return;
    }

    this.setState({inputState: SUBMITTING});
    this.props.setChildrenEditable(false);

    try {
      await this.props.onUpdate();

      this.setState({inputState: SUCCESS});

      setTimeout(() => {
        this.setState({inputState: DEFAULT});
      }, 3000);
    } catch {
      this.setState({inputState: ERROR});

      setTimeout(() => {
        this.setState({inputState: EDITING});
      }, 3000);
    }
  };

  getButtonPropsFromState = () => {
    const iconProps = {
      fontSize: 'small',
      color: 'secondary',
    };

    const {inputState} = this.state;

    if (inputState === ERROR) {
      return {
        icon: <Close {...iconProps} />,
        style: {background: 'red'},
      };
    }

    if (inputState === SUCCESS) {
      return {
        icon: <Check {...iconProps} />,
        style: {background: 'lightgreen'},
      };
    }

    if (inputState === SUBMITTING) {
      return {
        icon: <Spinner />,
        disabled: true,
        style: {background: 'green'}
      };
    }

    if (inputState === EDITING) {
      return {
        icon: <Save {...iconProps} />,
        style: {background: 'green'},
        onClick: () => this.onUpdate()
      };
    }

    return {
      icon: <Edit {...iconProps} />,
      onClick: () => {
        this.setState({inputState: EDITING});
        this.props.setChildrenEditable(true);
      },
    };
  };

  render() {
    const {icon, ...buttonProps} = this.getButtonPropsFromState();
    const isEditing = this.state.inputState === EDITING;
    return (
      <Row>
        {this.props.children}
        <IconButton {...buttonProps}>{icon}</IconButton>
      </Row>
    );
  }
}

export default ToggleEditGroup;

ToggleEditGroup.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  setChildrenEditable: PropTypes.func.isRequired,
  shouldUpdate: PropTypes.func.isRequired,
};

const Row = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

const IconButton = styled(Button).attrs(props => {
  const {palette} = props.theme;

  let background = palette.primary.main;
  let disabled = false;

  if (props.status === SUCCESS) {
    background = palette.success.light;
    disabled = true;
  }

  if (props.status === SUBMITTING) {
    background = palette.success.main;
    disabled = true;
  }

  if (props.status === EDITING) {
    background = palette.success.main;
  }

  return {
    disabled,
    style: {background},
    variant: 'contained',
    disableElevation: true,
  };
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  min-width: 20px;
  padding: 0;
  margin: 0;
  margin-right: 8px;

  &:hover {
    ${props =>
      props.status && `background-color: ${props.theme.palette.success.light}`};
  }
`;

const Spinner = styled(CircularProgress).attrs({
  color: 'secondary',
  size: 15,
})``;
