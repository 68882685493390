import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

import UpdateMember from '../components/Form/UpdateMember';
import UpdateParticipant from '../components/Form/UpdateParticipant';
import ChangeUserPasswordAndDeleteAccount from '../components/Form/ChangeUserPasswordAndDeleteAccount';
import {EmptyText, ErrorAlert, Spinner} from '../utils/commonStyledComponents';
import {fetchCities, fetchMemberRelationships} from '../utils/api';

const Account = () => {
  const [loading, setLoading] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [cities, setCities] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const [relations, cities] = await Promise.all([
          fetchMemberRelationships(),
          fetchCities(),
        ]);

        setParticipants(relations);
        setCities(cities);
        setLoading(false);
      } catch (e) {
        setShowErrorMessage(true);
      }
    })();
  }, []);

  if (showErrorMessage) {
    return <ErrorAlert>Si è verificato un errore di caricamento</ErrorAlert>;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container>
      <ChangeUserPasswordAndDeleteAccount />

      <Column>
        <UpdateMember cities={cities} />
        {renderParticipants(participants, cities)}
      </Column>
    </Container>
  );
};

const renderParticipants = (participants, cities) => {
  if (!participants.length) {
    //return <EmptyText>Nessun participante disponibile</EmptyText>;
    return <EmptyText></EmptyText>;
  }

  return participants.map((relation, index) => (
    <UpdateParticipant
      key={index}
      participant={relation.member}
      id={relation.id}
      type={relation.type}
      cities={cities}
    />
  ));
};

export default Account;

const Container = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    padding: 32px;
  }

  @media (min-width: 1024px) {
    flex-direction: row-reverse;
    align-items: flex-start;

    & > div:first-child {
      margin-left: 32px;
    }
  }
`;

const Column = styled.div`
  flex: 1;
`;
