import React, {useEffect} from 'react'
import {PayPalButtons, usePayPalScriptReducer} from "@paypal/react-paypal-js";
import {Spinner} from "../../utils/commonStyledComponents";

const style = {"layout": "vertical"};
export const NewPaymentButtonWrapper = ({
                                            onInit,
                                            currency,
                                            showSpinner,
                                            amount,
                                            onPaypalAnswered,
                                            onPaypalPaidOrder,
                                            onMarkAsPaymentError,
                                            acceptedPaymentMethods = ['paypal', 'mybank', 'card']
                                        }) => {
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{options, isPending}, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);

    const renderButton = (fundingSource) => <PayPalButtons
        key={fundingSource}
        fundingSource={fundingSource}
        style={style}
        disabled={false}
        forceReRender={[amount, currency]}
        createOrder={(data, actions) =>
            actions.order
                .create({
                    purchase_units: [
                        {
                            amount: {
                                currency_code: currency,
                                value: amount,
                            },
                        },
                    ],
                })}
        onApprove={function (data, actions) {
            return actions.order.capture().then(function () {
                onPaypalAnswered(true)
            }).then(function () {
                return onPaypalPaidOrder(data.orderID)
            });
        }}
        onError={err => {
            console.log({onError: err})
            onPaypalAnswered(true)
            onMarkAsPaymentError(err)
        }}
        onCancel={data => console.log({onCancel: data})}
        onInit={(_,__) => onInit()}
    />


    return (<>
            {(showSpinner && isPending) && <Spinner/>}
            {acceptedPaymentMethods.map(pm => renderButton(pm))}
        </>
    );
}
