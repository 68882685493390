import React from 'react';
import {Container} from "../utils/commonStyledComponents";
import {useUser} from '../utils/UserSession';

const IFrameComponent = ({section}) => {
    const {user} = useUser();
    if(section === null){
        //Significa che sto visualizzando l'iframe del docente, quindi devo prelevare io la sezione
        section = {
            content: user.docenteIframe
        }
    }
    return (
        <Container>
            <iframe
                style={{width: '100%', height: '1000px'}}
                src={section.content}
            />
        </Container>
    );
};

export default IFrameComponent;
