import React, {useState, useContext, useCallback} from 'react';

export const UserContext = React.createContext({logged: false});

export const UserProvider = ({children}) => {
  const [user, setUser] = useState({});

  const [token, setJWT] = useState(localStorage.getItem('TOKEN'));

  const setToken = useCallback(
    token => {
      localStorage.setItem('TOKEN', token);
      setJWT(token);
    },
    [setJWT]
  );

  return (
    <UserContext.Provider value={{user, setUser, token, setToken}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
