import formatDate from 'date-fns/format';

export const mutators = {
  setFiscalCode: (args, state, utils) => {
    utils.changeValue(state, 'fiscalCode', () => args[0]);
  },
  setField: (args, state, utils) => {
    utils.changeValue(state, args[1], () => args[0]);
  },
};

export const memberInitialValues = user => ({
  firstName: user.firstName,
  lastName: user.lastName,
  gender: user.gender,
  birthNation: user.birthNation,
  foreignBirthCity: user.foreignBirthCity,
  birthPlace: user.birthPlace,
  birthDate:
    user.birthDate && formatDate(new Date(user.birthDate), 'yyyy-MM-dd'),
  fiscalCode: user.fiscalCode,
  email1: user.email1,
  email2: user.email1,
  phone1: user.phone1,
  phone2: user.phone2,
  mobilePhone: user.mobilePhone,
  address: user.address,
  city: user.city,
  cap: user.cap,
  province: user.province,
  privacyAccepted: user.privacyAccepted,
  marketingAccepted: user.marketingAccepted,
  profilationAccepted: user.profilationAccepted,
  profilation2Accepted: user.profilation2Accepted,
  nationality: user.birthPlace ? 'Italiana' : 'Estera',
});

export const participantInitialValues = (participant, type) => ({
  firstName: participant.firstName,
  lastName: participant.lastName,
  gender: participant.gender,
  birthNation: participant.birthNation,
  foreignBirthCity: participant.foreignBirthCity,
  birthPlace: participant.birthPlace,
  birthDate:
    participant.birthDate &&
    formatDate(new Date(participant.birthDate), 'yyyy-MM-dd'),
  fiscalCode: participant.fiscalCode,
  email1: participant.email1,
  email2: participant.email1,
  phone1: participant.phone1,
  phone2: participant.phone1,
  mobilePhone: participant.mobilePhone,
  address: participant.address,
  city: participant.city,
  cap: participant.cap,
  province: participant.province,
  nationality: participant.birthPlace ? 'Italiana' : 'Estera',
  type,
});
