import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {Button as MuiButton, CircularProgress} from '@material-ui/core';

const Button = ({loading, text, ...rest}) => {
  const loadingToBinary = loading ? 1 : 0;
  return (
    <StyledButton {...rest} loading={loadingToBinary}>
      <Text loading={loadingToBinary}>{text}</Text>
      {loading && <Spinner />}
    </StyledButton>
  );
};

export default Button;

Button.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

const StyledButton = styled(MuiButton)`
  display: flex;
  ${props => props.loading && 'pointer-events: none;'}
`;

const Text = styled.p`
  padding: 0;
  margin: 0;
  opacity: ${props => (props.loading ? 0.3 : 1)};
`;

const Spinner = styled(CircularProgress).attrs({color: 'primary', size: 16})`
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 8px);
`;
