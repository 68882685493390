import React from 'react';
import PropTypes from 'prop-types';
import {Typography, Dialog, DialogTitle} from '@material-ui/core';

const FormErrorsPopup = ({title, errors, close}) => (
  <Dialog open={!!errors.length} onBackdropClick={close}>
    <DialogTitle>{title}</DialogTitle>
    <Typography component="div">
      <ul>
        {errors.map((error, i) => (
          <li key={i}>{error}</li>
        ))}
      </ul>
    </Typography>
  </Dialog>
);

FormErrorsPopup.propTypes = {
  title: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
};

export default FormErrorsPopup;
