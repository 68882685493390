import React, {useCallback} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Typography, Dialog, Button} from '@material-ui/core';

import {useUser} from '../utils/UserSession';
import config from '../utils/config';

const SHOP_URL = config.shopUrl;

const ModifyPractices = ({open, practice, close, setStatusPending}) => {
  const {token} = useUser();
  const link = `${SHOP_URL}?division=${practice.practiceDivisionId}&practiceid=${practice.id}&jwt=${token}`;

  const onModifyPractice = useCallback(() => {
    setStatusPending(practice.id);
    close();
  }, [close, practice.id, setStatusPending]);

  return (
    <Dialog open={open} onBackdropClick={close}>
      <DialogContent>
        <Text>
          Procedendo alla <Bold>Modifica della prenotazione </Bold>
          hai la possibilità di cambiare il tuo pacchetto e/o alcuni servizi ad
          esso collegati. Affinché il cambio vada a buon fine dovrai{' '}
          <Bold>
            completare tutti e 5 gli step come fosse una nuova prenotazione.
          </Bold>
          <br />
          Solo <Bold>concludendo lo step 5 la nuova prenotazione</Bold> avrà lo
          <Bold>
            {' '}
            stato opzionato (prenotazione attiva con posto bloccato e garantito)
          </Bold>{' '}
          e la <Bold>vecchia prenotazione </Bold> si trasformerà in{' '}
          <Bold>
            stato anullato (prenotazione non attiva che non garantisce nessun
            posto).
          </Bold>
          <br />
          Se nel compilare i campi per la modifica della prenotazione{' '}
          <Bold>
            uno o più servizi risultassero non più disponibili, non sarà
            possibile selezionarli{' '}
          </Bold>{' '}
          per la nuova prenotazione (modifica prenotazione) di quello{' '}
          <Bold>specifico pacchetto.</Bold> Per questa ragione la Giocamondo
          consiglia di fare cambi alla propria prenotazione entro il 16 Marzo.{' '}
          <br /> Attenzione: È necessario
          <Bold> inserire</Bold> nuovamente tutte le{' '}
          <Bold>note dello step 3,</Bold> anche se precedentemente comunicate.{' '}
          <br />
          Per assistenza si prega di far riferimento ai contatti sotto riportati
        </Text>

        <Row>
          <Button onClick={close}>Annulla</Button>

          <Link onClick={onModifyPractice} href={link}>
            Procedi con la modifica
          </Link>
        </Row>
      </DialogContent>
    </Dialog>
  );
};

ModifyPractices.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  setStatusPending: PropTypes.func,
  practice: PropTypes.shape({
    practiceDivisionId: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
};

export default ModifyPractices;

const DialogContent = styled(Typography).attrs({component: 'div'})`
  padding: 32px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 32px;
`;

const Link = styled(Button).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
  variant: 'contained',
  color: 'primary',
})`
  color: ${props => props.theme.palette.common.white};
`;

const Text = styled(Typography).attrs({variant: 'body1', component: 'span'})``;

const Bold = styled(Text)`
  font-weight: 600;
`;
